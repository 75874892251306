import { getModelTags } from '../../../../config/helpers';

export const contain = ['model_tags.Tags'];

export const filters = [
  {
    field: 'vertical_id',
    operator: '=',
    path: 'vertical_id'
  },
  {
    field: 'account_id',
    operator: '=',
    path: 'account_id'
  },
];

export const initialValues = (message_type_id, parent_id) => ({
  id: '',
  name: '',
  service_provider_id: '',
  message_type_id: message_type_id,
  from_email_component_id: '',
  friendly_from_component_id: '',
  email_body_component_id: '',
  email_subject_component_id: '',
//   parameters: '',
  parent_id: parent_id,
  model_tags: [],
  notes: [],
});

export const populateValues = (values, copy) => {
  const {
    id,
    name,
    service_provider_id,
    message_type_id,
    from_email_component_id,
    friendly_from_component_id,
    email_body_component_id,
    email_subject_component_id,
    // parameters,
    parent_id,
    model_tags,
    notes,
  } = values;
  return {
    id: copy ? '' : id,
    name: copy ? `Copy of ${name}` : name,
    service_provider_id,
    message_type_id,
    from_email_component_id,
    friendly_from_component_id,
    email_body_component_id,
    email_subject_component_id,
    // parameters,
    parent_id,
    model_tags: getModelTags(model_tags, copy),
    notes: notes || [],
  };
};

export const testInitialValues = props => ({
  to_email: '',
  subject: '',
  service_provider: '',
  ...props.values,
});

export const populateTestValues = (
  emailValues,
  textPlainPreview,
  emailPreview,
  vertical_id,
) => ({
  service_provider: emailValues.service_provider_id,
  to_email: emailValues.to_email,
  subject: emailValues.subject,
  text_body: textPlainPreview,
  html_body: emailPreview,
  vertical_id,
});

export const params = {
  direction: 'asc',
  sort: 'name',
  page: 1,
  limit: 500,
  search: '',
};
