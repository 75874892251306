import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: 28,
    marginBottom: -25,
    '& > label': {
      color: '#4F4F4F',
      marginBottom: 5,
      display: 'block',
      fontSize: 10,
      [theme.breakpoints.up(1200)]: {
        fontSize: 14,
      },
    },
  },
  formControl: {
    width: 142,
    zIndex: 999,
    '& > div > div': {
      padding: '7px 32px 7px 14px',
      border: '1px solid #828282',
      borderRadius: 4,
      fontSize: 14,
      '&:hover': {
        border: '1px solid #828282',
      },
      [theme.breakpoints.up(531)]: {
        fontSize: 12,
      },
      [theme.breakpoints.up(1200)]: {
        zIndex: 1,
        fontSize: 16,
        padding: '13px 32px 13px 14px',
      },
    },
    '& > div > fieldset > legend > span': {
      padding: 0,
    },
    [theme.breakpoints.up(531)]: {
      width: 133,
    },
    [theme.breakpoints.up(1200)]: {
      width: 145,
    },
  },
}));

const CustomFilterSelect = ({
  hideLabel,
  labelText,
  placeholder,
  handleChange,
  disabled,
  value,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.textField}>
      {hideLabel ? null : (
        <label htmlFor="icon" name="icon">
          {labelText || 'Folder Icon'}
        </label>
      )}
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={value}
          onChange={handleChange}
          disabled={disabled}
          placeholder={placeholder}
          displayEmpty
          label=""
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value="today">Today</MenuItem>
          <MenuItem value="yesterday">Yesterday</MenuItem>
          <MenuItem value="this-month">This Month</MenuItem>
          <MenuItem value="custom-filter">Custom Filter</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomFilterSelect;

CustomFilterSelect.propTypes = {
  hideLabel: PropTypes.string,
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
};
