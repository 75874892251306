import { get, post, patch, remove } from '../../../config/api';
import { mergeMap, catchError, takeUntil } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
  saveItem,
  saveList,
  saveListCount,
  createSave,
  removeSave,
  checkNameUniqueSuccess,
  getList,
} from '../../actions/actions/rest';
import { displaySnackbar, setItemFlag } from '../../actions/actions/root';
import {
  getAuth,
  getAccountVerticals,
  authRejected,
} from '../../actions/actions/auth';
import {
  GET_ITEM,
  GET_LIST,
  GET_LIST_COUNT,
  CREATE,
  REMOVE,
  EDIT,
  CHECK_NAME_UNIQUE,
  UPLOAD,
  CLONE,
} from '../../actions/actionTypes/rest';
import { of, merge } from 'rxjs';
import { consoleError } from '../../../config/helpers';
import { hideLoader } from '../../actions/actions/root';
import { hyphenToCamel } from '../../config';
import { handleErrorMessage } from './auth';

const generateMessage = string => {
  let stringCopy = string;
  if (string) {
    let stringArray = string.split('');
    if (stringArray[stringArray.length - 1] === 's') {
      stringCopy = string.substring(0, stringCopy.length - 1);
    }
  }
  return stringCopy;
};

const auth = action$ => {
  return action$.pipe(
    mergeMap(action => of(getAuth(action))),
    mergeMap(({ payload }) =>
      payload.auth
        .then(data => ({
          token: data.signInUserSession.accessToken.jwtToken,
          params: payload.data.payload,
        }))
        .catch(() => window.location.reload()),
    ),
    catchError(error => {
      window.location.reload();
      let message = handleErrorMessage(error);
      return merge(
        of(
          hideLoader(),
          authRejected(error),
          displaySnackbar({
            type: 'error',
            message,
          }),
        ),
      );
    }),
  );
};

export const getItemEpic = type => (action$, state$) => {
  return action$.pipe(
    ofType(GET_ITEM(type).actionTypes.START),
    action$ => auth(action$),
    mergeMap(({ params, token }) =>
      get(
        type,
        { 'Faucet-Token': token, 'Account-ID': state$.value.auth.account_id },
        params,
      ).pipe(
        mergeMap(data => [saveItem(type, data.response), hideLoader()]),
        catchError(error => {
          consoleError(error);
          let message = handleErrorMessage(error);
          return merge(
            of(
              hideLoader(),
              setItemFlag(true),
              displaySnackbar({
                type: 'error',
                message,
              }),
            ),
          );
        }),
        takeUntil(action$.pipe(ofType(GET_ITEM(type).actionTypes.FAILURE))),
      ),
    ),
  );
};

export const getListEpic = type => (action$, state$) => {
  return action$.pipe(
    ofType(GET_LIST(type).actionTypes.START),
    action$ => auth(action$),
    mergeMap(({ params, token }) =>
      get(
        type,
        { 'Faucet-Token': token, 'Account-ID': state$.value.auth.account_id },
        params,
      ).pipe(
        mergeMap(data => [saveList(type, data.response), hideLoader()]),
        catchError(error => {
          consoleError(error);
          let message = handleErrorMessage(error);
          return merge(
            of(
              hideLoader(),
              displaySnackbar({
                type: 'error',
                message,
              }),
            ),
          );
        }),
        takeUntil(action$.pipe(ofType(GET_LIST(type).actionTypes.FAILURE))),
      ),
    ),
  );
};

export const getListCountEpic = type => (action$, state$) => {
  return action$.pipe(
    ofType(GET_LIST_COUNT(type).actionTypes.START),
    action$ => auth(action$),
    mergeMap(({ params, token }) =>
      get(
        `${type}/count`,
        { 'Faucet-Token': token, 'Account-ID': state$.value.auth.account_id },
        params,
      ).pipe(
        mergeMap(data => [saveListCount(type, data.response), hideLoader()]),
        catchError(error => {
          consoleError(error);
          return merge(
            of(
              hideLoader(),
              displaySnackbar({
                type: 'error',
                message: 'API count error.',
              }),
            ),
          );
        }),
        takeUntil(
          action$.pipe(ofType(GET_LIST_COUNT(type).actionTypes.FAILURE)),
        ),
      ),
    ),
  );
};

export const createEpic = type => (action$, state$) => {
  return action$.pipe(
    ofType(CREATE(type).actionTypes.START),
    action$ => auth(action$),
    mergeMap(({ params, token }) =>
      post(
        type,
        { 'Faucet-Token': token, 'Account-ID': state$.value.auth.account_id },
        JSON.stringify(params.body),
        params,
      ).pipe(
        mergeMap(({ response }) => {
          let { contain, filters, params } = state$.value[hyphenToCamel(type)];
          const actions = [
            getList(type, { params, contain, filters }, false),
            createSave(type, response),
            displaySnackbar({
              type: 'success',
              message: `Successfully created ${generateMessage(type.replace(/-/g, ' '))}`,
            }),
            hideLoader(),
          ];
          return type === 'verticals'
            ? [...actions, getAccountVerticals()]
            : actions;
        }),
        catchError(error => {
          consoleError(error);
          let message = handleErrorMessage(error);
          return merge(
            of(
              hideLoader(),
              displaySnackbar({
                type: 'error',
                message,
              }),
            ),
          );
        }),
        takeUntil(action$.pipe(ofType(CREATE(type).actionTypes.FAILURE))),
      ),
    ),
  );
};

export const editEpic = type => (action$, state$) => {
  return action$.pipe(
    ofType(EDIT(type).actionTypes.START),
    action$ => auth(action$),
    mergeMap(({ params, token }) =>
      patch(
        `${type}/${params.body.id}`,
        { 'Faucet-Token': token, 'Account-ID': state$.value.auth.account_id },
        JSON.stringify(params.body),
      ).pipe(
        mergeMap(() => {
          let { contain, filters, params } = state$.value[hyphenToCamel(type)];
          const actions = [
            getList(type, { params, contain, filters }, false),
            displaySnackbar({
              type: 'success',
              message: `Successfully edited ${generateMessage(
                type.replace(/-/g, ' '),
              )}.`,
            }),
            hideLoader(),
          ];
          return type === 'verticals'
            ? [...actions, getAccountVerticals()]
            : actions;
        }),
        catchError(error => {
          consoleError(error);
          let message = handleErrorMessage(error);
          return merge(
            of(
              hideLoader(),
              displaySnackbar({
                type: 'error',
                message,
              }),
            ),
          );
        }),
        takeUntil(action$.pipe(ofType(EDIT(type).actionTypes.FAILURE))),
      ),
    ),
  );
};

export const removeEpic = type => (action$, state$) => {
  return action$.pipe(
    ofType(REMOVE(type).actionTypes.START),
    action$ => auth(action$),
    mergeMap(({ params, token }) =>
      remove(
        `${type}/${params.body.id}`,
        { 'Faucet-Token': token, 'Account-ID': state$.value.auth.account_id },
        JSON.stringify(params.body),
      ).pipe(
        mergeMap(() => {
          let { filters, contain } = state$.value[hyphenToCamel(type)];
          let parameters = state$.value[hyphenToCamel(type)].params;
          const actions = [
            getList(type, { params: parameters, contain, filters }, false),
            removeSave(type, params.body),
            displaySnackbar({
              type: 'success',
              message: `Successfully deleted ${generateMessage(type.replace(/-/g, ' '))}.`,
            }),
            hideLoader(),
          ];
          return type === 'verticals'
            ? [...actions, getAccountVerticals()]
            : actions;
        }),
        catchError(error => {
          consoleError(error);
          let message = handleErrorMessage(error);
          return merge(
            of(
              hideLoader(),
              displaySnackbar({
                type: 'error',
                message,
              }),
            ),
          );
        }),
        takeUntil(action$.pipe(ofType(REMOVE(type).actionTypes.FAILURE))),
      ),
    ),
  );
};

export const checkNameUniqueEpic = type => (action$, state$) => {
  return action$.pipe(
    ofType(CHECK_NAME_UNIQUE(type).actionTypes.START),
    action$ => auth(action$),
    mergeMap(({ params, token }) =>
      get(
        type,
        { 'Faucet-Token': token, 'Account-ID': state$.value.auth.account_id },
        params,
      ).pipe(
        mergeMap(data => [checkNameUniqueSuccess(type, data.response)]),
        catchError((error, caught) => {
          consoleError(error);
          let message = handleErrorMessage(error);
          return merge(
            of(
              hideLoader(),
              displaySnackbar({
                type: 'error',
                message,
              }),
            ),
          );
        }),
        takeUntil(
          action$.pipe(ofType(CHECK_NAME_UNIQUE(type).actionTypes.FAILURE)),
        ),
      ),
    ),
  );
};

export const uploadEpic = type => (action$, state$) => {
  return action$.pipe(
    ofType(UPLOAD(type).actionTypes.START),
    action$ => auth(action$),
    mergeMap(({ params, token }) =>
      post(
        type,
        { 'Faucet-Token': token, 'Account-ID': state$.value.auth.account_id },
        params.body,
        params,
      ).pipe(
        mergeMap(({ response }) => {
          let { contain, filters, params } = state$.value[hyphenToCamel(type)];
          const actions = [
            getList(type, { params, contain, filters }, false),
            createSave(type, response),
            displaySnackbar({
              type: 'success',
              message: 'Request for file upload is sent!',
            }),
            hideLoader(),
          ];
          return actions;
        }),
        catchError(error => {
          consoleError(error);
          let message = handleErrorMessage(error);
          return merge(
            of(
              hideLoader(),
              displaySnackbar({
                type: 'error',
                message,
              }),
            ),
          );
        }),
        takeUntil(action$.pipe(ofType(CREATE(type).actionTypes.FAILURE))),
      ),
    ),
  );
};

export const cloneEpic = type => (action$, state$) => {
    return action$.pipe(
      ofType(CLONE(type).actionTypes.START),
      action$ => auth(action$),
      mergeMap(({ params, token }) =>
        post(
          type,
          { 'Faucet-Token': token, 'Account-ID': state$.value.auth.account_id },
          JSON.stringify(params.body),
          params,
        ).pipe(
          mergeMap(({ response }) => {
            let { contain, filters, params } = state$.value[hyphenToCamel(type)];
            const actions = [
              getList(type, { params, contain, filters }, false),
              createSave(type, response),
              displaySnackbar({
                type: 'success',
                message: `Successfully cloned ${generateMessage(type.replace(/-/g, ' '))}`,
              }),
              hideLoader(),
            ];
            return type === 'verticals'
              ? [...actions, getAccountVerticals()]
              : actions;
          }),
          catchError(error => {
            consoleError(error);
            let message = handleErrorMessage(error);
            return merge(
              of(
                hideLoader(),
                displaySnackbar({
                  type: 'error',
                  message,
                }),
              ),
            );
          }),
          takeUntil(action$.pipe(ofType(CREATE(type).actionTypes.FAILURE))),
        ),
      ),
    );
  };
