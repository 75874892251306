import React from 'react';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Table from './httpRequestComponentTable';
import { populateValues, initialValues } from './config';
import DripComponent from '../dripComponent/dripComponent';

const HttpRequestComponent = () => {
  return (
    <DripComponent
      title="Generic Post Component"
      item="Generic Post Component"
      urlPath="generic-post-components"
      model_name="GenericPostComponent"
      fields={[
        'name',
        'post_url',
        'post_type',
        'post_fields',
        'post_encoding',
        'post_response',
      ]}
      Table={Table}
      populateValues={populateValues}
      initialValues={initialValues}
      modal
    />
  );
};

export default withTitle(
  withDashboard(HttpRequestComponent, false),
  'Generic Post Component',
);
