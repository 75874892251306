import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100% !important',
    padding: '0 0 20px 0',
    marginTop: 60,
    overflowX: 'hidden',
    paddingLeft: 0,
    marginLeft: -18,
    [theme.breakpoints.up(1200)]: {
      marginTop: 0,
      paddingLeft: 60,
      marginLeft: 0,
    },
    [theme.breakpoints.up('xl')]: {
      padding: '0 0 20px 0',
      maxWidth: 1650,
      margin: '0 auto !important',
    },
  },
  containerHasVertical: {
    width: '100% !important',
    padding: '0px 0 20px 0px',
    marginLeft: -20,
    position: 'relative',
    marginTop: 74,
    [theme.breakpoints.up(1200)]: {
      marginTop: 0,
      marginLeft: 0,
      padding: '50px 0 20px 60px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '50px 0 20px 270px',
    },
  },
  notesTopContainer: {
    display: 'block',
    width: '100%',
    paddingTop: 0,
    marginTop: -40,
    position: 'fixed',
    top: 130,
    zIndex: 999,
    background: '#fafafa',
    paddingBottom: 10,
    [theme.breakpoints.up(1200)]: {
      paddingTop: 0,
      marginTop: 0,
      paddingBottom: 0,
      position: 'static',
      top: 0,
      zIndex: 1,
    },
  },
  notesHasTopContainer: {
    display: 'block',
    width: '100%',
    paddingTop: 20,
    marginTop: -40,
    position: 'fixed',
    top: 130,
    zIndex: 999,
    background: '#fafafa',
    paddingBottom: 10,
    [theme.breakpoints.up(1200)]: {
      paddingTop: 0,
      marginTop: 0,
      paddingBottom: 0,
      position: 'static',
      top: 0,
      zIndex: 1,
    },
  },
  notesTitle: {
    paddingLeft: 0,
    marginBottom: 0,
    position: 'relative',
    top: 20,
    left: 20,
    fontSize: 20,
    [theme.breakpoints.up(768)]: {
      top: 40,
    },
    [theme.breakpoints.up(1200)]: {
      fontSize: 36,
      top: 46,
    },
  },
  title: {
    paddingLeft: 0,
    marginBottom: 0,
    position: 'relative',
    top: 46,
    left: 20,
    fontSize: 20,
    [theme.breakpoints.up(1200)]: {
      fontSize: 36,
    },
  },
  titleHasVertical: {
    top: 0,
  },
  subtitle: {
    position: 'relative',
    top: 45,
    left: 20,
    display: 'none',
    [theme.breakpoints.up(768)]: {
      display: 'block',
      fontSize: 14,
    },
    [theme.breakpoints.up(1200)]: {
      fontSize: 20,
    },
  },
  subtitleHasVertical: {
    top: 0,
  },
  titleIcon: {
    position: 'absolute',
    left: 106,
    top: 117,
    [theme.breakpoints.up('xl')]: {
      left: 317,
    },
  },
  contentContainer: {
    marginTop: 45,
    paddingLeft: 23,
  },
  contentContainerHasVertical: {
    [theme.breakpoints.up(1200)]: {
      marginTop: 0,
    },
  },
  formContainer: {
    width: '100%',
  },
  searchField: {
    backgroundColor: '#F2F2F2',
    '& > .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 15px) scale(1)',
    },
    '& > .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& > div': {
      paddingRight: 0,
      '& > input': {
        backgroundColor: theme.palette.white[100],
        borderRight: '1px solid #828282',
      },
      '& > button': {
        padding: 9,
      },
      '& > svg': {
        color: '#828282',
        position: 'relative',
        left: 7,
      },
    },
  },
  searchContainer: {
    '& > div': {
      width: 200,
    },
  },
  searchGridContainerNotes: {
    width: '100%',
    display: 'block',
    marginLeft: 19,
    marginTop: 30,
    [theme.breakpoints.up(768)]: {
      position: 'absolute',
      textAlign: 'right',
      right: 20,
      top: 0,
    },
    [theme.breakpoints.up(1200)]: {
      maxWidth: 700,
      width: 700,
      right: 25,
      top: 105,
    },
    [theme.breakpoints.up(1920)]: {
      right: 210,
    },
  },
  searchGridContainer: {
    maxWidth: 700,
    width: 700,
    display: 'block',
    marginLeft: 19,
    marginTop: 10,
    [theme.breakpoints.up(1200)]: {
      right: 0,
      top: 124,
      position: 'absolute',
      textAlign: 'right',
    },
  },
  searchGridContainerHasVertical: {
    top: 45,
  },
  tableContainer: {
    marginTop: 10,
    boxShadow: 'none',
    background: '#FAFAFA',
    paddingRight: 17,
  },
  userValue: {
    'word-break': 'break-all',
  },
  tableCell: {
    maxWidth: 125,
    width: 125,
    fontSize: 14,
    [theme.breakpoints.up(1200)]: {
      fontSize: 16,
    },
  },
  tableSortLable: {
    lineHeight: '17px',
  },
  tableCellHeader: {
    maxWidth: 125,
    width: 125,
    fontSize: 14,
    fontWeight: 900,
  },
  tableNameCell: {
    maxWidth: 145,
    width: 145,
    fontSize: 14,
    [theme.breakpoints.up(1200)]: {
      fontSize: 16,
    },
  },
  tableBody: {
    '& > tr:nth-of-type(odd)': {
      backgroundColor: '#F2F2F2',
    },
  },
  tableCellHeaderActive: {
    maxWidth: 125,
    width: 125,
    fontSize: 14,
    fontWeight: 900,
  },
  tableCellLink: {
    fontSize: 14,
    color: '#036BF0',
    transition: 'all .7s',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.up(1200)]: {
      fontSize: 16,
    },
  },
  seeMore: {
    fontSize: 14,
    color: '#036BF0',
    transition: 'all .7s',
    cursor: 'pointer',
    paddingLeft: 10,
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.up(1200)]: {
      fontSize: 16,
    },
  },
  cssFocused: {
    color: '#828282 !important',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#828282 !important',
  },
  pageInput: {
    backgroundColor: '#F2F2F2',
    width: 115,
    '& > div': {
      paddingRight: 0,
      '& > input': {
        backgroundColor: theme.palette.white[100],
        borderRight: '1px solid #828282',
        padding: '13px 10px',
      },
      '& > span': {
        padding: 10,
        cursor: 'pointer',
      },
    },
  },
  resultsPerPageLabel: {
    fontSize: 16,
    textAlign: 'center',
    color: theme.palette.text[100],
    paddingRight: 10,
    '& > span': {
      display: 'block',
    },
  },
  resultsPerPage: {
    width: 75,
    '& > div': {
      paddingRight: 0,
      '& > input': {
        padding: 13,
      },
      '& > div': {
        backgroundColor: theme.palette.grey[100],
        padding: 2,
        '& > svg': {
          fontSize: 19,
        },
      },
    },
  },
  noData: {
    height: 60,
    position: 'relative',
    left: 20,
    fontSize: 14,
    [theme.breakpoints.up(1200)]: {
      fontSize: 20,
    },
  },
  topActionContainer: {
    height: 42,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
    top: 42,
  },
  tableRow: {
    backgroundColor: '#fafafa !important',
  },
  pagination: {
    marginTop: 20,
    display: 'none',
    [theme.breakpoints.up(1200)]: {
      display: 'block',
    },
  },
  modalLoader: {
    minHeight: 300,
    position: 'absolute',
    width: '100%',
    top: '50%',
    left: 0,
    zIndex: 10,
  },
  tableContent: {
    paddingBottom: 40,
    [theme.breakpoints.up(1200)]: {
      paddingBottom: 0,
    },
  },
  viewMore: {
    width: '100%',
    textAlign: 'center',
    position: 'fixed',
    zIndex: 999,
    bottom: 0,
    left: 0,
    background: '#fafafa',
    '& > span': {
      display: 'inline-block',
      color: theme.palette.lightBlue[300],
      cursor: 'pointer',
      transition: 'all .4s',
      fontSize: 14,
      fontFamily: 'Lato',
      paddingBottom: 33,
      paddingTop: 33,
      '&:hover': {
        color: theme.palette.lightBlue[400],
      },
    },
    [theme.breakpoints.up(1200)]: {
      display: 'none',
    },
  },
}));

export default useStyles;
