import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { NavLink } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FlowIcon from '../../assets/images/flows-white.svg';
import ReportingIcon from '../../assets/images/Reports_menu_icon.svg';
import DripsIcon from '../../assets/images/drips-icon-white.svg';
// import ABTest from '../../assets/images/ABtest.svg';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.blue[250],
    paddingLeft: 25,
    marginTop: 0,
    position: 'relative',
    zIndex: 1000,
    [theme.breakpoints.up('sm')]: {
      marginTop: 47,
    },
  },
  link: {
    color: theme.palette.common.white,
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
    color: theme.palette.common.white,
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    },
  },
  secondNested: {
    paddingLeft: theme.spacing(6),
    color: theme.palette.common.white,
    transition: 'all .4s',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    },
  },
  icon: {
    width: 24,
    paddingLeft: 3,
  },
  smallItemList: {
    justifyContent: 'flex-end',
    paddingRight: 0,
    position: 'relative',
    left: 7,
  },
  activeClass: {
    '& > div > span': {
      fontFamily: 'Helvetica',
      fontWeight: 'bold',
    },
  },
  listItemIcon: {
    minWidth: 33,
  },
  listItem: {
    maxHeight: 49,
  },
}));

const DrawerList = ({ handleClick, open }) => {
  const classes = useStyles();
  const vertical_id = useSelector(state => state.auth.vertical_id);

  const convertPath = path => {
    return `${path}?vertical_id=${vertical_id}`;
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem
        button
        onClick={handleClick('drips')}
        className={classes.listItem}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <img src={DripsIcon} alt="Drips" className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="Drips" />
        {open.drips ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open.drips} timeout="auto" unmountOnExit>
        <ListItem
          component={NavLink}
          to={convertPath('/drips/list')}
          className={classes.nested}
          activeClassName={classes.activeClass}
        >
          <ListItemText primary="Drips List" />
        </ListItem>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={handleClick('dripModule')}
          >
            <ListItemText primary="Drip Components" />
            {open.dripModule ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        </List>
      </Collapse>
      <Collapse in={open.dripModule} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            component={NavLink}
            activeClassName={classes.activeClass}
            to={convertPath('/drip-components/email-html-body')}
            className={classes.secondNested}
          >
            <ListItemText primary="Email HTML Body" />
          </ListItem>
          <ListItem
            component={NavLink}
            activeClassName={classes.activeClass}
            to={convertPath('/drip-components/email-friendly-from')}
            className={classes.secondNested}
          >
            <ListItemText primary="Friendly Froms" />
          </ListItem>
          <ListItem
            component={NavLink}
            to={convertPath(`/drip-components/from-email`)}
            className={classes.secondNested}
            activeClassName={classes.activeClass}
          >
            <ListItemText primary="From Emails" />
          </ListItem>
          <ListItem
            component={NavLink}
            activeClassName={classes.activeClass}
            to={convertPath(`/drip-components/email-subject-text`)}
            className={classes.secondNested}
          >
            <ListItemText primary="Email Subject Text" />
          </ListItem>
          <ListItem
            component={NavLink}
            activeClassName={classes.activeClass}
            to={convertPath(`/drip-components/sms-body-text`)}
            className={classes.secondNested}
          >
            <ListItemText primary="SMS Body Text" />
          </ListItem>
          <ListItem
            component={NavLink}
            activeClassName={classes.activeClass}
            to={convertPath(`/drip-components/call-campaign-id`)}
            className={classes.secondNested}
          >
            <ListItemText primary="Call Campaign ID" />
          </ListItem>
          <ListItem
            component={NavLink}
            activeClassName={classes.activeClass}
            to={convertPath(`/drip-components/rvm-list`)}
            className={classes.secondNested}
          >
            <ListItemText primary="RVM List" />
          </ListItem>
          <ListItem
            component={NavLink}
            activeClassName={classes.activeClass}
            to={convertPath(`/drip-components/http-request`)}
            className={classes.secondNested}
          >
            <ListItemText primary="Generic Post Component" />
          </ListItem>
        </List>
      </Collapse>
      <ListItem
        button
        onClick={handleClick('flows')}
        className={classes.listItem}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <img src={FlowIcon} alt="flow" className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="Flows" />
        {open.flows ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open.flows} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            component={NavLink}
            to={convertPath('/flows/list')}
            className={classes.secondNested}
            activeClassName={classes.activeClass}
          >
            <ListItemText primary="Flow List" />
          </ListItem>
          <ListItem
            component={NavLink}
            to={convertPath('/flows/steps')}
            className={classes.secondNested}
            activeClassName={classes.activeClass}
          >
            <ListItemText primary="Flow Steps" />
          </ListItem>
          <ListItem
            component={NavLink}
            to={convertPath('/flows/transitions')}
            className={classes.secondNested}
            activeClassName={classes.activeClass}
          >
            <ListItemText primary="Flow Transition" />
          </ListItem>
          <ListItem
            component={NavLink}
            to={convertPath('/flows/uploads')}
            className={classes.secondNested}
            activeClassName={classes.activeClass}
          >
            <ListItemText primary="Uploads" />
          </ListItem>
        </List>
      </Collapse>
      <ListItem
        button
        onClick={handleClick('reporting')}
        className={classes.listItem}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <img src={ReportingIcon} alt="reporting" className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="Reporting" />
        {open.reporting ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open.reporting} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            component={NavLink}
            to={convertPath('/reporting/overall')}
            className={classes.secondNested}
            activeClassName={classes.activeClass}
          >
            <ListItemText primary="Overall" />
          </ListItem>
          <ListItem
            component={NavLink}
            to={convertPath('/reporting/flows')}
            className={classes.secondNested}
            activeClassName={classes.activeClass}
          >
            <ListItemText primary="Flows" />
          </ListItem>
          <ListItem
            component={NavLink}
            to={convertPath('/reporting/emails')}
            className={classes.secondNested}
            activeClassName={classes.activeClass}
          >
            <ListItemText primary="Emails" />
          </ListItem>
          <ListItem
            component={NavLink}
            to={convertPath('/reporting/sms')}
            className={classes.secondNested}
            activeClassName={classes.activeClass}
          >
            <ListItemText primary="SMS" />
          </ListItem>
          <ListItem
            component={NavLink}
            to={convertPath('/reporting/rvm')}
            className={classes.secondNested}
            activeClassName={classes.activeClass}
          >
            <ListItemText primary="RVM" />
          </ListItem>
          <ListItem
            component={NavLink}
            to={convertPath('/reporting/calls')}
            className={classes.secondNested}
            activeClassName={classes.activeClass}
          >
            <ListItemText primary="Calls" />
          </ListItem>
          <ListItem
            component={NavLink}
            to={convertPath('/reporting/generic-data-post')}
            className={classes.secondNested}
            activeClassName={classes.activeClass}
          >
            <ListItemText primary="Generic Data Post" />
          </ListItem>
        </List>
      </Collapse>
      {/* <ListItem button className={classes.listItem}>
        <ListItemIcon className={classes.listItemIcon}>
          <img src={ABTest} alt="AB Test" className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary="AB Tests" />
      </ListItem> */}
    </List>
  );
};

export default DrawerList;
