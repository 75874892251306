import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/actions/actions/auth';
import { useHistory } from 'react-router-dom';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import classNames from 'classnames';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles(theme => ({
  menuContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    right: -16,
  },
  menuInput: {
    width: 180,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 48,
    position: 'relative',
    top: 5,
    cursor: 'pointer',
    transition: 'all .4s',
    padding: '10px 5px',
    border: `1px solid ${theme.palette.white[100]}`,
    borderRadius: 5,
    display: 'none',
    [theme.breakpoints.up(1200)]: {
      display: 'flex',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    '& > svg': {
      width: '10%',
    },
  },
  menuInputText: {
    textAlign: 'center',
    width: '90%',
  },
  mobileIcon: {
    display: 'block',
    position: 'relative',
    bottom: 3,
    cursor: 'pointer',
    marginRight: 13,
    '& > svg': {
      fontSize: 30,
    },
    [theme.breakpoints.up(1200)]: {
      display: 'none',
    },
  },
  menuItem: {
    background: '#5D83B4',
    color: theme.palette.white[100],
    fontFamily: 'Lato',
    borderBottom: `1px solid rgba(157, 196, 246, 0.5)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    marginRight: 10,
    fontSize: 18,
    height: 51,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  menuItemActive: {
    background: 'rgba(0, 0, 0, 0.04)',
  },
}));

const StyledMenu = withStyles({
  paper: {
    width: 220,
    borderRadius: 5,
    background: '#5D83B4',
    marginTop: 6,
    '& > ul': {
      padding: '0',
      position: 'relative',
    },
  },
})(props => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    {...props}
  />
));

const MenuSelect = ({ isAccountManager }) => {
  const classes = useStyles();
  const [menu, setMenu] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const accountMenuTitle = useSelector(state => state.app.accountMenuTitle);
  const [menuOpen, setMenuOpen] = useState(null);

  const handleClick = event => {
    setMenuOpen(event.currentTarget);
  };

  const handleClose = () => {
    setMenuOpen(null);
  };

  const handleClickMenuItem = path => () => {
    setMenu(path);
    switchPage(path);
  };

  const switchPage = value => {
    switch (value) {
      case 'SignOut':
        dispatch(logout());
        Auth.signOut();
        break;
      case 'SettingsHome':
        history.push('/settings');
        break;
      case 'Webhook Actions':
        history.push('/settings/webhook-actions');
        break;
      case 'Alerts':
        history.push('/settings/alerts');
        break;
      case 'Service Providers':
        history.push('/settings/provider-config');
        break;
      case 'Suppression List':
        history.push('/settings/suppression-list');
        break;
      case 'Verticals':
        history.push('/settings/verticals');
        break;
      case 'Tags':
        history.push('/settings/tags');
        break;
      case 'Users':
        history.push('/settings/users');
        break;
      case 'Accounts':
        history.push('/settings/switch-accounts');
        break;
      case 'Manage Accounts':
        history.push('/settings/manage-accounts');
        break;
      case 'Notes':
        history.push('/notes');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (history.location.pathname.substring(1) === 'settings') {
      setMenu('SettingsHome');
    } else if (
      history.location.pathname.substring(1) === 'settings/webhook-actions'
    ) {
      setMenu('Webhook Actions');
    } else if (
      history.location.pathname.substring(1) === 'settings/provider-config'
    ) {
      setMenu('Service Providers');
    } else if (
      history.location.pathname.substring(1) === 'settings/suppression-list'
    ) {
      setMenu('Suppression List');
    } else if (
      history.location.pathname.substring(1) === 'settings/verticals'
    ) {
      setMenu('Verticals');
    } else if (history.location.pathname.substring(1) === 'settings/tags') {
      setMenu('Tags');
    } else if (history.location.pathname.substring(1) === 'settings/users') {
      setMenu('Users');
    } else if (history.location.pathname.substring(1) === 'settings/alerts') {
      setMenu('Alerts');
    } else if (
      history.location.pathname.substring(1) === 'settings/manage-accounts'
    ) {
      setMenu('Manage Accounts');
    } else if (history.location.pathname.substring(1) === 'notes') {
      setMenu('Notes');
    }
  }, [history]);

  useEffect(() => {
    if (accountMenuTitle) {
      setMenu(accountMenuTitle);
    }
  }, [accountMenuTitle]);

  return (
    <>
      <div className={classes.menuContainer}>
        <div
          className={classes.menuInput}
          onClick={handleClick}
          aria-controls="verticals-menu"
          aria-haspopup="true"
        >
          <div className={classes.menuInputText}>{menu ? menu : 'ACCOUNT'}</div>
          {menuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </div>
        <div
          className={classes.mobileIcon}
          onClick={handleClick}
          aria-controls="verticals-menu"
          aria-haspopup="true"
        >
          <PersonIcon />
        </div>
      </div>
      <StyledMenu
        id="verticals-menu"
        anchorEl={menuOpen}
        open={Boolean(menuOpen)}
        onClose={handleClose}
      >
        {!isAccountManager && (
          <MenuItem
            value="Webhook Actions"
            className={classNames(classes.menuItem, {
              [classes.menuItemActive]: menu === 'Webhook Actions',
            })}
            onClick={handleClickMenuItem('Webhook Actions')}
          >
            Webhook Actions
          </MenuItem>
        )}
        {/* {!isAccountManager && (
          <MenuItem
            value="Alerts"
            className={classNames(classes.menuItem, {
              [classes.menuItemActive]: menu === 'Alerts',
            })}
            onClick={handleClickMenuItem('Alerts')}
          >
            Alerts
          </MenuItem>
        )} */}
        {!isAccountManager && (
          <MenuItem
            value="Service Providers"
            className={classNames(classes.menuItem, {
              [classes.menuItemActive]: menu === 'Service Providers',
            })}
            onClick={handleClickMenuItem('Service Providers')}
          >
            Service Providers
          </MenuItem>
        )}
        {!isAccountManager && (
          <MenuItem
            value="Suppression List"
            className={classNames(classes.menuItem, {
              [classes.menuItemActive]: menu === 'SuppressionList',
            })}
            onClick={handleClickMenuItem('Suppression List')}
          >
            Suppression List
          </MenuItem>
        )}
        {/* <MenuItem
          value="Users"
          className={classNames(classes.menuItem, {
            [classes.menuItemActive]: menu === 'Users',
          })}
          onClick={handleClickMenuItem('Users')}
        >
          Users
        </MenuItem> */}
        {!isAccountManager && (
          <MenuItem
            value="Verticals"
            className={classNames(classes.menuItem, {
              [classes.menuItemActive]: menu === 'Verticals',
            })}
            onClick={handleClickMenuItem('Verticals')}
          >
            Verticals
          </MenuItem>
        )}
        {!isAccountManager && (
          <MenuItem
            value="Tags"
            className={classNames(classes.menuItem, {
              [classes.menuItemActive]: menu === 'Tags',
            })}
            onClick={handleClickMenuItem('Tags')}
          >
            Tags
          </MenuItem>
        )}
        <MenuItem
          value="Accounts"
          className={classNames(classes.menuItem, {
            [classes.menuItemActive]: menu === 'Accounts',
          })}
          onClick={handleClickMenuItem('Accounts')}
        >
          Switch Accounts
        </MenuItem>
        {isAccountManager && (
          <MenuItem
            value="Manage Accounts"
            className={classNames(classes.menuItem, {
              [classes.menuItemActive]: menu === 'Manage Accounts',
            })}
            onClick={handleClickMenuItem('Manage Accounts')}
          >
            Manage Accounts
          </MenuItem>
        )}
        <MenuItem
          value="Notes"
          className={classNames(classes.menuItem, {
            [classes.menuItemActive]: menu === 'Notes',
          })}
          onClick={handleClickMenuItem('Notes')}
        >
          Notes
        </MenuItem>
        <MenuItem
          value="SignOut"
          className={classNames(classes.menuItem, {
            [classes.menuItemActive]: menu === 'SignOut',
          })}
          onClick={handleClickMenuItem('SignOut')}
        >
          Sign Out
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export default MenuSelect;
