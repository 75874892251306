import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: 1200,
    margin: '0 auto',
    paddingTop: 25,
    [theme.breakpoints.up(1200)]: {
      paddingTop: 0,
    },
    [theme.breakpoints.up(1650)]: {
      width: '1650px !important',
      margin: '0 auto !important',
    },
  },
  greyLink: {
    color: theme.palette.grey[200],
    fontSize: 18,
    textDecoration: 'underline',
    cursor: 'pointer',
    transition: 'all .7s',
    '&:hover': {
      color: '#036BF0',
    },
  },
  links: {
    color: '#036BF0',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  date: {
    color: theme.palette.grey[200],
    fontSize: 15,
  },
  recentActivity: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 5,
    padding: '10px 17px',
    marginTop: `${theme.spacing(1)}px !important`,
    marginBottom: `${theme.spacing(1)}px !important`,
  },
  borders: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  alertsDates: {
    color: theme.palette.grey[200],
  },
  whatChangedText: {
    fontSize: 14,
    [theme.breakpoints.up('xl')]: {
      fontSize: 16,
    },
  },
  notesTitle: {
    fontSize: 24,
    fontFamily: 'Lato',
    color: theme.palette.text[100],
  },
  tableCell: {
    background: '#F3F3F3',
    fontSize: '14px !important',
    [theme.breakpoints.up(1200)]: {
        fontSize: '16px !important',
    },
  },
  columnLabel: {
    fontSize: '14px !important',
    backgroundColor: '#F3F3F3',
    [theme.breakpoints.up(1200)]: {
      fontSize: '16px !important',
    },
  },
  link: {
    color: '#036BF0',
    textDecoration: 'underline',
    cursor: 'pointer',
    transition: 'all .7s',
    fontSize: 14,
    [theme.breakpoints.up(1200)]: {
      fontSize: 16,
    },
    '&:hover': {
      color: theme.palette.text[100],
    },
  },
  allNotesButton: {
    fontSize: 14,
    fontFamily: 'Lato',
    color: `${theme.palette.white[100]} !important`,
    backgroundColor: `${theme.palette.lightBlue[300]} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.lightBlue[400]} !important`,
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#F3F8FE',
    padding: '11px 9px 16px 9px',
  },
  userValue: {
    'word-break': 'break-all',
  },
  title: {
    fontSize: 20,
    fontWeight: 900,
    [theme.breakpoints.up(1200)]: {
      fontSize: 36,
    },
  },
  subtitle: {
    lineHeight: 1.4,
    fontSize: 16,
    [theme.breakpoints.up(1200)]: {
      fontSize: 20,
      lineHeight: 1.75,
    },
  },
}));

export default useStyles;
