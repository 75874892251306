import React, { useState, useEffect, Fragment } from 'react';
import {
  getList,
  cancelList,
  saveParams,
  saveFilters,
  saveContain,
} from '../store/actions/actions/rest';
import { useSelector, useDispatch } from 'react-redux';
import { getFilters } from '../hoc/withTable/getFilters';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Loader from './loader';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const RecentNotesTable = ({
  classes,
  params,
  filters,
  contain,
  renderElements,
  columns,
  getItemLink,
  handleClickAllNotes,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const smallTable = useMediaQuery(theme.breakpoints.down(1200));
  const loader = useSelector(state => state.app.loader);
  const verticalList = useSelector(state => state.verticals.list);
  const account_id = useSelector(state => state.auth.account_id);
  const auth = useSelector(state => state.auth);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const list = useSelector(state => state.notes.list);
  const [rows, setRows] = useState([]);

  useEffect(() => {
      /**
     *  We are checking if we some of the condition is true
     *  First we are checking if the vertical_id is true, account_id is true (is not 0) and auth exist
     *  Or in case if the first condirion is false, we are checking if account_id is 0
     *  That means that we are on Account Manager role
     */
    if ((auth && vertical_id && account_id) || account_id === 0) {
      let allFilters = getFilters(filters, auth);
      dispatch(saveParams('notes', params));
      dispatch(saveFilters('notes', allFilters));
      dispatch(saveContain('notes', contain));
      dispatch(cancelList('notes', null, dispatch));
      dispatch(
        getList(
          'notes',
          { params, contain: contain, filters: [...allFilters] },
          dispatch,
        ),
      );
    }
    return () => {
      dispatch(cancelList('notes', null, dispatch));
    };
  }, [auth, vertical_id, account_id, dispatch, params, filters, contain]);

  useEffect(() => {
    if (list && list.length > 0 && verticalList && verticalList.length > 0) {
      renderElements(list, setRows, verticalList);
    } else {
      setRows([]);
    }
  }, [list, verticalList, renderElements]);

  const renderNoDataSection = () => {
    if (!list || list.length === 0) {
      return (
        <TableRow>
          <td className={classes.noData}>No Items</td>
        </TableRow>
      );
    }
  };

  const handleClickName = item => () => {
    const itemPath = getItemLink(item);
    if (itemPath) {
      history.push({
        pathname: `${itemPath}${item.model_id}`,
        search: `vertical_id=${item.vertical_id}`,
      });
    }
  };

  const renderColumn = (id, row, value) => {
    if (id === 'name') {
      const path = getItemLink(row.item);
      const renderLinkClassName =
        path && value !== 'Deleted Item' ? classes.link : classes.text;
      if (value === 'Deleted Item') {
        return <span className={classes.userValue}>{value}</span>;
      } else {
        return (
          <span
            className={renderLinkClassName}
            onClick={handleClickName(row.item)}
          >
            {value}
          </span>
        );
      }
    } else return <span className={classes.userValue}>{value}</span>;
  };

  return (
    <div>
      <div className={classes.titleContainer}>
        <div className={classes.notesTitle}>Notes</div>
        <Button
          className={classes.allNotesButton}
          onClick={handleClickAllNotes}
          variant="contained"
        >
          All Notes
        </Button>
      </div>
      <Fragment>
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className={classes.table}
            size={smallTable ? 'small' : 'medium'}
          >
            <TableHead>
              <TableRow>
                {columns.map(column => {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className={classes.columnLabel}
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      <b>{column.label}</b>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows.map(row => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.item.id}
                    >
                      {columns.map(column => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            className={classes.tableCell}
                            key={Math.floor(Math.random() * 123132)}
                            align={column.align}
                            style={{ fontSize: 16 }}
                          >
                            {renderColumn(column.id, row, value)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {renderNoDataSection()}
            </TableBody>
          </Table>
        </TableContainer>
        {Boolean(loader) && <Loader open={Boolean(loader)} />}
      </Fragment>
    </div>
  );
};

export default RecentNotesTable;

RecentNotesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  contain: PropTypes.array.isRequired,
  renderElements: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  getItemLink: PropTypes.func.isRequired,
  handleClickAllNotes: PropTypes.func.isRequired,
};
