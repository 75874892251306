import React from 'react';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import withDashboard from '../../hoc/withDashboard/withDashboard';
import withTitle from '../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import GreyRectangleImage from '../../assets/images/grey-rectangle.png';
import { useHistory } from 'react-router-dom';

const Settings = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = page => () => {
    history.push(`/settings/${page}`);
  };

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12}>
        <Typography
          variant="h1"
          component="h2"
          gutterBottom
        >
          Settings
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Grid container item xs={6} onClick={handleClick('actions')}>
          <Grid container item xs={12} className={classes.settingsItem}>
            <Grid item xs={12} lg={4} xl={5}>
              <img src={GreyRectangleImage} alt="grey rectangle" />
            </Grid>
            <Grid container item xs={12} lg={8} xl={7}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Actions
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  component="h2"
                  gutterBottom
                  className={classes.settingsText}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={6} onClick={handleClick('provider-config')}>
          <Grid container item xs={12} className={classes.settingsItem}>
            <Grid item xs={12} lg={4} xl={5}>
              <img src={GreyRectangleImage} alt="grey rectangle" />
            </Grid>
            <Grid container item xs={12} lg={8} xl={7}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Service Provider Configurations
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  component="h2"
                  gutterBottom
                  className={classes.settingsText}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={6} onClick={handleClick('suppression-lists')}>
          <Grid container item xs={12} className={classes.settingsItem}>
            <Grid item xs={12} lg={4} xl={5}>
              <img src={GreyRectangleImage} alt="grey rectangle" />
            </Grid>
            <Grid container item xs={12} lg={8} xl={7}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Suppression Lists
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  component="h2"
                  gutterBottom
                  className={classes.settingsText}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={6} onClick={handleClick('uploads')}>
          <Grid container item xs={12} className={classes.settingsItem}>
            <Grid item xs={12} lg={4} xl={5}>
              <img src={GreyRectangleImage} alt="grey rectangle" />
            </Grid>
            <Grid container item xs={12} lg={8} xl={7}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Uploads
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  component="h2"
                  gutterBottom
                  className={classes.settingsText}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={6}>
          <Grid container item xs={12} className={classes.settingsItem}>
            <Grid item xs={12} lg={4} xl={5}>
              <img src={GreyRectangleImage} alt="grey rectangle" />
            </Grid>
            <Grid container item xs={12} lg={8} xl={7}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Unsub Keys
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  component="h2"
                  gutterBottom
                  className={classes.settingsText}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={6} onClick={handleClick('users')}>
          <Grid container item xs={12} className={classes.settingsItem}>
            <Grid item xs={12} lg={4} xl={5}>
              <img src={GreyRectangleImage} alt="grey rectangle" />
            </Grid>
            <Grid container item xs={12} lg={8} xl={7}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Users
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  component="h2"
                  gutterBottom
                  className={classes.settingsText}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={6}>
          <Grid container item xs={12} className={classes.settingsItem}>
            <Grid item xs={12} lg={4} xl={5}>
              <img src={GreyRectangleImage} alt="grey rectangle" />
            </Grid>
            <Grid container item xs={12} lg={8} xl={7}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  component="h2"
                  gutterBottom
                  className={classes.settingsText}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={6} onClick={handleClick('verticals')}>
          <Grid container item xs={12} className={classes.settingsItem}>
            <Grid item xs={12} lg={4} xl={5}>
              <img src={GreyRectangleImage} alt="grey rectangle" />
            </Grid>
            <Grid container item xs={12} lg={8} xl={7}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Verticals
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  component="h2"
                  gutterBottom
                  className={classes.settingsText}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTitle(withDashboard(Settings, true), 'Settings');
