import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Formik, Form } from 'formik';
import NameField from '../../../../components/form/name';
import CustomSelect from '../../../../components/form/customSelect';
import DeleteButton from '../../../../components/customButtons/customDelete';
import PublishButton from '../../../../components/customButtons/customPublish';
import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import {
  createStart,
  editStart,
  removeStart,
} from '../../../../store/actions/actions/rest';
import { useDispatch, useSelector } from 'react-redux';
import { populateValues } from './config';
import Loader from '../../../../components/loader';
import { useHistory } from 'react-router-dom';
import ModelTags from '../../../../components/modelTags/modelTags';
import { sendTestEmail } from '../../../../store/actions/actions/test';
import { testInitialValues, populateTestValues } from './config';
import PropTypes from 'prop-types';

const EmailDripForm = ({
  formValues,
  classes,
  fromEmailComponentsList,
  friendlyFromComponentsList,
  emailBodyComponentsList,
  emailSubjectComponentsList,
  serviceProvidersList,
  elementId,
  handleEmailPreview,
  setFormValues,
  emailPreview,
  textPlainPreview,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const account_id = useSelector(state => state.auth.account_id);
  const vertical_id = useSelector(state => state.auth.vertical_id);
  const user_name = useSelector(state => state.auth.username);
  const loader = useSelector(state => state.app.loader);
  const [flag, setFlag] = useState(false);
  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen,
  ] = useState(false);
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [isSendTestEmailModalOpen, setIsSendTestEmailModalOpen] = useState(
    false,
  );
  const DeleteConfirmationDialog = lazy(() =>
    import('../../../../components/deleteConfirmationModal'),
  );
  const NotesModal = lazy(() => import('../../../../components/notes/notes'));
  const SendTestEmailModal = lazy(() =>
    import('../../../../components/testModal/testModal'),
  );

  useEffect(() => {
    if (flag && !loader) {
      history.push({
        pathname: '/drips/list',
        search: `vertical_id=${vertical_id}`,
      });
      setFlag(false);
    }
    // eslint-disable-next-line
  }, [flag, loader, history]);

  const handleDelete = notes => {
    const data = {
      body: {
        id: formValues.id,
        notes,
      },
    };
    dispatch(removeStart('drips', data, dispatch));
    history.push({
      pathname: '/drips/list',
      search: `vertical_id=${vertical_id}`,
    });
  };

  const onSubmit = values => {
    if (elementId) {
      handleOpenNotesModal();
    } else {
      let allValues = populateValues(values);
      let data = {
        body: {
          ...allValues,
          account_id,
          vertical_id,
          notes: [
            {
              account_id,
              vertical_id,
              model_name: 'Drip',
              model_id: '',
              user_name,
              note: `Created ${values && values.name ? values.name : ''}`,
            },
          ],
        },
      };
      dispatch(createStart('drips', data, dispatch));
      setFlag(true);
    }
  };

  const submitForm = (values, notes) => {
    let allValues = populateValues(values);
    let data = {
      body: {
        ...allValues,
        account_id,
        vertical_id,
        notes,
      },
    };
    dispatch(editStart('drips', data, dispatch));
    history.push({
      pathname: '/drips/list',
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleDeleteModalClose = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleCloseNotesModal = () => {
    setNotesModalOpen(false);
  };

  const handleOpenNotesModal = () => {
    setNotesModalOpen(true);
  };

  const handleCloseSendTestEmailModal = () => {
    setIsSendTestEmailModalOpen(false);
  };

  // eslint-disable-next-line
  const handleOpenSendTestEmailModal = () => {
    setIsSendTestEmailModalOpen(true);
  };

  const handleSendTestEmail = emailValues => {
    let emailSubject = emailSubjectComponentsList.find(
      subject => +subject.id === +emailValues.email_subject_component_id,
    );
    emailValues.subject = emailSubject.name;
    let data = populateTestValues(
      emailValues,
      textPlainPreview,
      emailPreview,
      vertical_id,
    );
    dispatch(sendTestEmail(data));
    handleCloseSendTestEmailModal();
  };

  const renderSendTestEmailModal = props =>
    isSendTestEmailModalOpen ? (
      <Suspense
        fallback={
          <Grid item xs={12} className={classes.modalLoader}>
            <Loader open={isSendTestEmailModalOpen} />
          </Grid>
        }
      >
        <SendTestEmailModal
          open={isSendTestEmailModalOpen}
          fields={['to_email']}
          title="Email"
          handleClose={handleCloseSendTestEmailModal}
          serviceProvidersList={serviceProvidersList}
          values={testInitialValues(props)}
          onSubmit={handleSendTestEmail}
        />
      </Suspense>
    ) : null;

  const renderNotesModal = props =>
    notesModalOpen ? (
      <Suspense
        fallback={
          <Grid item xs={12} className={classes.modalLoader}>
            <Loader open={notesModalOpen} />
          </Grid>
        }
      >
        <NotesModal
          props={props}
          classes={classes}
          model_name="Drip"
          model_id={elementId}
          open={notesModalOpen}
          handleClose={handleCloseNotesModal}
          submitForm={submitForm}
        />
      </Suspense>
    ) : null;

  const renderDeleteConfirmationDialog = isDeleteConfirmationModalOpen ? (
    <Suspense
      fallback={
        <Grid item xs={12} className={classes.modalLoader}>
          <Loader open={Boolean(loader)} />
        </Grid>
      }
    >
      <DeleteConfirmationDialog
        open={isDeleteConfirmationModalOpen}
        handleDelete={handleDelete}
        handleClose={handleDeleteModalClose}
        itemName={'email drip'}
        model_id={elementId}
        model_name="Drip"
      />
    </Suspense>
  ) : null;

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize={true}
      onSubmit={values => {
        onSubmit(values);
      }}
    >
      {props => (
        <Form className={classes.formContainer}>
          <NameField
            {...props}
            placeholder="Email Drip Name"
            labelText="Email Drip Name"
            name="name"
            wrapperClass={classes.textField}
            type="drips"
            dripType="2"
            id={elementId}
          />
          <CustomSelect
            {...props}
            placeholder="Service Provider"
            labelText="Service Provider"
            name="service_provider_id"
            wrapperClass={classes.textField}
            options={serviceProvidersList}
          />
          <CustomSelect
            {...props}
            placeholder="From Email"
            labelText="From Email"
            name="from_email_component_id"
            wrapperClass={classes.textField}
            options={fromEmailComponentsList}
          />
          <CustomSelect
            {...props}
            placeholder="Friendly From Drip Component"
            labelText="Friendly From Drip Component"
            name="friendly_from_component_id"
            wrapperClass={classes.textField}
            options={friendlyFromComponentsList}
          />
          <CustomSelect
            {...props}
            placeholder="Subject Text Drip Component"
            labelText="Subject Text Drip Component"
            name="email_subject_component_id"
            wrapperClass={classes.textField}
            options={emailSubjectComponentsList}
          />
          <CustomSelect
            {...props}
            placeholder="HTML Email Body Drip Component"
            labelText="HTML Email Body Drip Component"
            name="email_body_component_id"
            wrapperClass={classes.textField}
            options={emailBodyComponentsList}
            customHandleChange={handleEmailPreview}
          />
          {/* <ParametersField
            {...props}
            placeholder="Parameters"
            labelText="Parameters"
            name="parameters"
            wrapperClass={classes.textField}
          /> */}
          <ModelTags
            props={props}
            model_name="Drip"
            model_id={`${elementId}`}
            formValues={formValues}
            setFormValues={setFormValues}
          />
          <Grid
            item
            xs={12}
            className={classes.actionButtonsContainer}
            container
            alignItems="center"
          >
            <Grid
              item
              xs={4}
              container
              justify="flex-start"
              alignItems="center"
              className={classes.sendTestEmailBtn}
            >
              {/* <Button
                onClick={() => {
                  props.validateForm().then(errors => {
                    if (
                      Object.keys(errors).length === 0 &&
                      errors.constructor === Object
                    ) {
                      handleOpenSendTestEmailModal();
                    } else {
                      props.submitForm();
                    }
                  });
                }}
              >
                Send Test Email
              </Button> */}
            </Grid>
            <Grid
              item
              xs={12}
              container
              spacing={1}
              className={classes.actionButtons}
            >
              {elementId && <DeleteButton onClick={handleDeleteModalOpen} />}
              <PublishButton type="submit" />
            </Grid>
          </Grid>
          {renderDeleteConfirmationDialog}
          {renderNotesModal(props)}
          {renderSendTestEmailModal(props)}
        </Form>
      )}
    </Formik>
  );
};
export default EmailDripForm;

EmailDripForm.propTypes = {
  formValues: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  fromEmailComponentsList: PropTypes.array,
  friendlyFromComponentsList: PropTypes.array,
  emailBodyComponentsList: PropTypes.array,
  emailSubjectComponentsList: PropTypes.array,
  serviceProvidersList: PropTypes.array,
  elementId: PropTypes.string,
  handleEmailPreview: PropTypes.func.isRequired,
  setFormValues: PropTypes.func.isRequired,
  emailPreview: PropTypes.string.isRequired,
  textPlainPreview: PropTypes.string.isRequired,
};
