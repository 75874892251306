import React from 'react';
import { Formik, Form } from 'formik';
import NameField from '../../../../components/form/name';
import CustomSelect from '../../../../components/form/customSelect';
import DeleteButton from '../../../../components/customButtons/customDelete';
import PublishButton from '../../../../components/customButtons/customPublish';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import withDripForm from '../../../../hoc/withDripForm';

const CallDripFrom = ({
  onSubmit,
  handleDeleteModalOpen,
  classes,
  formValues,
  elementId,
  serviceProvidersList,
  renderDeleteConfirmationDialog,
  renderNotesModal,
}) => {
  const callComponentsList = useSelector(state => state.callComponents.list);
  return (
    <Formik
      initialValues={formValues}
      enableReinitialize={true}
      onSubmit={values => {
        onSubmit(values);
      }}
    >
      {props => (
        <Form className={classes.formContainer}>
          <NameField
            {...props}
            placeholder="Call Drip Name"
            labelText="Call Drip Name"
            name="name"
            wrapperClass={classes.textField}
            type="drips"
            dripType="4"
            id={elementId}
          />
          <CustomSelect
            {...props}
            placeholder="Call Campaign"
            labelText="Call Campaign"
            name="call_component_id"
            wrapperClass={classes.textField}
            options={callComponentsList}
          />
          <CustomSelect
            {...props}
            placeholder="Service Provider"
            labelText="Service Provider"
            name="service_provider_id"
            wrapperClass={classes.textField}
            options={serviceProvidersList}
          />
          {/* <ParametersField
            {...props}
            placeholder="Parameters"
            labelText="Parameters"
            name="parameters"
            wrapperClass={classes.textField}
          /> */}
          <Grid item xs={12} className={classes.actionButtons}>
            {elementId && <DeleteButton onClick={handleDeleteModalOpen} />}
            <PublishButton type="submit" />
            {renderDeleteConfirmationDialog}
          </Grid>
          {renderNotesModal(props)}
        </Form>
      )}
    </Formik>
  );
};
export default withDripForm(CallDripFrom);
