import React from 'react';
import { renderElements, columns, sortBy, filters } from './config';
import ReportingTable from '../singleReportingPage/table';

const SmsReporting = () => {
  return (
    <ReportingTable
      name="SMSes"
      renderElements={renderElements}
      columns={columns}
      sortBy={sortBy}
      itemPath="sms-drip"
      filters={filters}
      messageTypeId="1"
    />
  );
};

export default SmsReporting;
