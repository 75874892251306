import React from 'react';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Table from './emailHtmlBodyTable';
import { useHistory } from 'react-router-dom';
import DripComponent from '../dripComponent/dripComponent';
import { initialValues, populateValues } from './config';
import { useSelector } from 'react-redux';

const EmailHtmlBody = () => {
  const history = useHistory();
  const vertical_id = useSelector(state => state.auth.vertical_id);

  const handleClickName = item => () => {
    const id = item.id;
    history.push({
      pathname: `/drip-components/email-html-body/edit/${id}`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  const handleClickNewButton = () => {
    history.push({
      pathname: `/drip-components/email-html-body/add`,
      search: `vertical_id=${vertical_id}`,
    });
  };

  return (
    <DripComponent
      title="Email HTML Bodies"
      item="Email Body"
      urlPath="email-body-components"
      model_name=""
      fields={[]}
      Table={Table}
      populateValues={populateValues}
      initialValues={initialValues}
      modal={false}
      customHandleNewButton={handleClickNewButton}
      customHandleClickItem={handleClickName}
    />
  );
};

export default withTitle(
  withDashboard(EmailHtmlBody, false),
  'Email HTML Body',
);
