import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import withDashboard from '../../../hoc/withDashboard/withDashboard';
import withTitle from '../../../hoc/withTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Table from './serviceProviderTable';
import Loader from '../../../components/loader';
import Search from '../../../components/search';
import { initialValues, populateValues } from './config';
import ServiceProvidersModal from '../../../components/modal/modal';

const ServiceProviders = () => {
  const classes = useStyles();
  const loader = useSelector(state => state.app.loader);
  const [search, setSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [parameters, setParameters] = useState(null);
  const [clickedElement, setClickedElement] = useState(null);

  const handleCloseModal = () => {
    setClickedElement(null);
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleClickName = item => () => {
    setClickedElement(item);
    handleOpenModal();
  };

  return (
    <Grid container className={classes.container}>
      <Grid container item xs={12}>
        <Grid
          item
          xs={6}
          container
          alignContent="flex-end"
          justify="flex-start"
        >
          <Typography variant="h1" component="h2">
          Service Provider Configuration
        </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justify="flex-end"
          alignItems="flex-end"
        >
          <Grid item className={classes.addButton}>
            <Button onClick={handleOpenModal} startIcon={<AddIcon />}>
              New Configuration
            </Button>
          </Grid>
          <Grid item>
            <Search
              type="Configuration"
              setSearch={setSearch}
              classes={classes}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.tableWrapper}>
        <Grid item xs={12}>
          <Table
            type="service-providers"
            search={search}
            classes={classes}
            setParameters={setParameters}
            handleClickName={handleClickName}
          />
        </Grid>
        {Boolean(loader) && <Loader open={Boolean(loader)} />}
      </Grid>
      <ServiceProvidersModal
        open={isModalOpen}
        fields={['name', 'alias', 'parameters']}
        populateValues={populateValues}
        title="Service Provider Configuration"
        handleClose={handleCloseModal}
        element={clickedElement || initialValues}
        parameters={parameters}
        path="service-providers"
        model_name="ServiceProvider"
      />
    </Grid>
  );
};

export default withTitle(
  withDashboard(ServiceProviders, true),
  'Provider Config',
);
