import React from 'react';
import { SignIn } from 'aws-amplify-react';
import { withStyles } from '@material-ui/core/styles';
import Logo from '../../../assets/images/login-logo.png';
import Button from '@material-ui/core/Button';
import { styles } from './styles';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';

class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ['signIn', 'signedOut'];
  }
  showComponent = () => {
    const { classes } = this.props;

    const handleSubmit = e => {
      e.preventDefault();
      this.props.handleMessage(0);
      return super.signIn();
    };

    const renderLoader = this.state.loading ? (
      <CircularProgress className={classes.loader} />
    ) : (
      'Continue'
    );

    return (
      <div className={classes.backgroundLayer}>
        <div className={classes.loginContainer}>
          <div>
            <img src={Logo} alt="logo" className={classes.logo} />
            {this.props.message ? (
              <div className={classes.message}>
                Password has been successfully reset
              </div>
            ) : null}
            <form onSubmit={handleSubmit}>
              <Input
                disableUnderline={true}
                className={classes.inputField}
                id="username"
                key="username"
                name="username"
                onChange={this.handleInputChange}
                type="text"
                placeholder="User name"
                required
              />
              <Input
                type="password"
                disableUnderline={true}
                placeholder="Password"
                className={classes.inputField}
                onChange={this.handleInputChange}
                id="password"
                key="password"
                name="password"
                required
              />
              <div
                onClick={() => super.changeState('forgotPassword')}
                className={classes.forgotPasswordLink}
              >
                Forgot User Name or Password?
              </div>
              <Button className={classes.loginButton} type="submit">
                {renderLoader}
              </Button>
            </form>
          </div>
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(CustomSignIn);
