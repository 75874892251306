import { getModelTags } from '../../../../config/helpers';

export const contain = ['model_tags.Tags'];

export const initialValues = {
  id: '',
  name: '',
  service_provider_id: '',
  rvm_component_id: '',
//   parameters: '',
  message_type_id: '',
  parent_id: '',
  model_tags: [],
  notes: [],
};

export const populateValues = (values, copy) => {
  const {
    id,
    name,
    service_provider_id,
    rvm_component_id,
    // parameters,
    message_type_id,
    parent_id,
    model_tags,
    notes,
  } = values;
  return {
    id: copy ? '' : id,
    name: copy ? `Copy of ${name}` : name,
    service_provider_id,
    rvm_component_id,
    // parameters,
    message_type_id,
    parent_id,
    model_tags: getModelTags(model_tags, copy),
    notes: notes || [],
  };
};
